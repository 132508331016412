<template>
  <div
    id="printMe"
    class="geplante-bewegungen mb-12 border-b-2 border-gray-300 pb-8"
  >
    <div
      class="flex justify-center items-center text-center col-span-4 space-x-2"
    >
      <button @click="previousYear">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <datepicker
        style="cursor: pointer !important; width: 5rem"
        class="
          relative
          border-none
          text-2xl text-center
          date-input
          bg-secondary
          text-white
          rounded-md
          py-0
        "
        :placeholder="'Month'"
        :inputFormat="'yyyy'"
        :minimumView="'year'"
        :maximumView="'year'"
        :initialView="'year'"
        v-model="transferPlanningDate"
      ></datepicker>
      <button @click="nextYear">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
    <section class="gp-bewegungen overflow-x-auto">
      <div
        class="
          main-action
          gap-2
          text-sm text-secondary
          rounded
          mt-5
          md:flex
          justify-between
        "
      >
        <div class="add print-display">
          <button
            type="button"
            @click="openModal({}, 'new')"
            title="neue Bewegungen erstellen"
            class="
              shadow-lg
              flex
              items-center
              text-lg
              border-2 border-secondary
              bg-secondary
              text-white
              py-1
              px-2
              rounded-lg
              font-light
              hover:text-secondary hover:bg-white
            "
          >
            <span>{{ $t("new planning of a transfer") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="gp-bewegungen-top-row w-440 sm:w-full">
        <div
          class="
            text-sm
            justify-between
            text-primary
            mb-4
            mt-4
            rounded
            border-2 border-secondary
            px-5
            py-2
            grid grid-cols-3
            sm:grid-cols-4
            gap-2
            print-flex print-flex-row
          "
        >
          <div class="gp-bew-cat print-w-3 print-text-lg">
            <span>{{ $t("From account") }}</span>
          </div>
          <div
            class="
              gp-bew-betrag
              text-center
              print-w-3 print-text-right print-text-lg
            "
          >
            <span>{{ $t("To account") }}</span>
          </div>
          <div
            class="
              gp-bew-pers
              text-right
              print-w-3 print-text-right print-text-lg
            "
          >
            <span>{{ $t("amount in €") }}</span>
          </div>
          <div
            class="
              gp-bew-pers
              text-center
              hidden
              sm:block
              print-w-3 print-text-right print-text-lg
            "
          >
            <span>{{ $t("Due Date") }}</span>
          </div>
          <!-- <div class="gp-bew-show text-right print-display hidden sm:block">
            <span>{{ $t("action") }}</span>
          </div> -->
        </div>
      </div>

      <div class="w-440 sm:w-full">
        <div v-if="transferList.data">
          <div v-for="(item, index) in transferList.data" :key="index">
            <div
              @click="openModal(item, 'update')"
              class="
                items-center
                grid grid-cols-3
                sm:grid-cols-4
                gap-2
                bg-gray-100
                rounded-md
                text-gray-700
                my-px
                py-2
                px-5
                mt-1
                cursor-pointer
                print-flex print-flex-row
              "
            >
              <div class="items-center print-w-3">
                <span class="font-semibold text-sm capitalize">{{
                  item.source_account ? $t(item.source_account.name) : "-"
                }}</span>
              </div>
              <div class="items-center text-center print-w-3 print-text-right">
                <span class="font-semibold text-sm text-center capitalize">{{
                  item.destination_account
                    ? $t(item.destination_account.name)
                    : "-"
                }}</span>
              </div>
              <div class="items-center text-right print-w-3 print-text-right">
                <span class="font-semibold text-sm capitalize">{{
                  parseFloat(item.amount).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                  })
                }}</span>
              </div>
              <div
                class="
                  items-center
                  text-center
                  hidden
                  sm:block
                  print-w-3 print-text-right
                "
              >
                <span class="font-semibold text-sm capitalize">
                  {{ dueDate(item) }}
                </span>
              </div>
              <!-- <div class="flex justify-end print-display">
                <div class="edit">
                  <button>
                    <PencilAltIcon
                      class="h-6 w-6 text-fuchsia-600"
                      @click="openModal(item, 'update')"
                    />
                  </button>
                </div>
                <div class="delete">
                  <button>
                    <TrashIcon
                      class="h-6 w-6 text-red-400"
                      @click="deletePlan(item.id)"
                    />
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
     <nav class="bg-white px-4 py-3 flex flex-col sm:flex-row items-center justify-between border-t border-gray-200 sm:px-6 print-display" aria-label="Pagination">
        <div v-if="transferList.meta" class="flex flex-col items-center">
          <p class="text-sm text-gray-700">
            {{ $t("Showing") }}
            <span class="font-medium">{{ transferList.meta.from || 0 }}</span>
            {{ $t("to") }}
            <span class="font-medium">{{ transferList.meta.to || 0 }}</span>
            {{ $t("of") }}
            <span class="font-medium">{{ transferList.meta.total || 0 }}</span>
            {{ $t("results") }}
          </p>
          <div class="text-sm text-gray-700 ml-3 mt-2">
            {{ $t("show per page") }}
            <select @change="formatCountPagination" v-model="count" class="rounded-md border-gray-300">
              <option :selected="count == 10" value="10">10</option>
              <option :selected="count == 20" value="20">20</option>
              <option :selected="count == 30" value="30">30</option>
              <option :selected="count == 1000" value="1000">{{ $t("All") }}</option>
            </select>
          </div>
        </div>
      <div v-if="transferList.meta" class="flex-1 flex justify-between sm:justify-end mt-2 sm:mt-0">
          <button
            @click="firstPageTransferList"
            :class="
              transferList.meta.current_page == 1
                ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
            "
            class="mr-1 relative inline-flex items-center px-1 py-2"
          >
            <ChevronDoubleLeftIcon class="h-4 w-4" />
          </button>
          <button
            @click="prePageTransferList"
            :disabled="transferList.meta.current_page == 1"
            :class="
              transferList.meta.current_page == 1
                ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40'
                : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
            "
            class="relative inline-flex items-center px-4 py-2"
          >
            {{ $t("Previous") }}
          </button>
          <button
            @click="nextPageTransferList"
            :disabled="transferList.meta.current_page == transferList.meta.last_page"
            :class="
              transferList.meta.current_page == transferList.meta.last_page
                ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
            "
            class="ml-3 relative inline-flex items-center px-4 py-2"
          >
            {{ $t("Next") }}
          </button>
          <button
            @click="nextLastPageTransferList"
            :class="
              transferList.meta.current_page == transferList.meta.last_page
                ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
            "
            class="ml-1 relative inline-flex items-center px-1 py-2"
          >
            <ChevronDoubleRightIcon class="h-4 w-4" />
          </button>
        </div>
      </nav>
  </div>
  <modal
    :open="showModal"
    @close="showModal = false"
    :type="type"
    :data="transfer"
    :mode="mode"
  ></modal>
</template>

<script>
import Modal from "@/components/Modal";
import Datepicker from "../../node_modules/vue3-datepicker";

import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from "@heroicons/vue/outline";

const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default {
  components: { Modal, Datepicker ,ChevronDoubleRightIcon, ChevronDoubleLeftIcon},
  data() {
    return {
      showModal: false,
      type: null,
      transfer: null,
      mode: null,
      monthList,
      year: new Date().getFullYear(),
      sumIncome: 0,
      sumExpense: 0,
      sumSave: "0",
      transferPlanningDate: new Date(),
      count: 10,
      page: 1,
    };
  },
  watch: {
    transferPlanningDate() {
      this.$store.commit("setMainDate", this.transferPlanningDate);
      this.getTransferList();
    },
  },
  computed: {
    transferList() {
      return this.$store.state.transfer.transferList;
    },
    mainDate() {
      return this.$store.state.mainDate;
    },
  },
  methods: {
     nextPageTransferList() {
      this.page = this.transferList.meta.current_page + 1;
      this.getTransferList();
    },
    nextLastPageTransferList() {
      this.page = this.transferList.meta.last_page;
      this.getTransferList();
    },
    prePageTransferList() {
      this.page = this.transferList.meta.current_page - 1;
      this.getTransferList();
    },
    firstPageTransferList() {
      this.page = 1;
      this.getTransferList();
    },
    formatCountPagination() {
      this.getTransferList();
    },
    getTransferList() {
      this.$store.dispatch("transfer/getTransferList", {
        start: `${this.transferPlanningDate.getFullYear()}-01-01`,
        end: `${this.transferPlanningDate.getFullYear()}-12-31`,
        count: this.count,
        page: this.page,
      });
    },
    openModal(transfer, mode) {
      this.$store.dispatch("accounts/getAccountList" , {count:100}).then(() => {
        this.showModal = true;
        this.transfer = transfer;
        this.mode = mode;
        this.type = "transferModal";
      });
    },
    dueDate(plan) {
      if (plan.repetition == "0") {
        const indexDate = +plan.date.slice(5, 7);
        return monthList[indexDate - 1];
      }
      if (plan.repetition == "m" && plan.repetition_n !== 0) {
        const endDateYear = plan.repetition_e.slice(0, 4);
        const endDateMonth = plan.repetition_e.slice(5, 7);
        const period = plan.repetition_f > 1 ? plan.repetition_f : "";
        const details = `Alle ${period} Monate bis ${endDateMonth}-${endDateYear}`;
        return details;
      }
      if (plan.repetition == "y" && !plan.repetition_n == 0) {
        const endDate = plan.repetition_e.slice(0, 4);
        const period = plan.repetition_f > 1 ? plan.repetition_f : "";
        const details = `Alle ${period} Jahre bis ${endDate}`;
        return details;
      }
      if (plan.repetition == "m" && plan.repetition_n == 0) {
        const period = plan.repetition_f > 1 ? plan.repetition_f : "";
        const details = `Alle ${period} Monate `;
        return details;
      }
      if (plan.repetition == "y" && plan.repetition_n == 0) {
        const period = plan.repetition_f > 1 ? plan.repetition_f : "";
        const details = `Alle ${period} Jahre `;
        return details;
      }
    },
    deletePlan(id) {
      this.$store.dispatch("transfer/deleteTransfer", id);
    },
    nextYear() {
      this.transferPlanningDate = new Date(
        this.transferPlanningDate.getFullYear() + 1,
        11,
        31
      );
    },
    previousYear() {
      this.transferPlanningDate = new Date(
        this.transferPlanningDate.getFullYear() - 1,
        11,
        31
      );
    },
  },
  mounted() {
    this.getTransferList();
    this.$store.commit("setMainDate", new Date());
  },
};
</script>

<style scoped>
.v3dp__input_wrapper,
.date-input {
  width: 5rem;
}

.date-input {
  cursor: pointer !important;
}
</style>
